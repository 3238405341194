(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("luxon"));
	else if(typeof define === 'function' && define.amd)
		define(["luxon"], factory);
	else if(typeof exports === 'object')
		exports["rrule"] = factory(require("luxon"));
	else
		root["rrule"] = factory(root["luxon"]);
})(typeof self !== 'undefined' ? self : this, function(__WEBPACK_EXTERNAL_MODULE__2__) {
return 